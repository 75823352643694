import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = ({ name, job }) => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-pink-600">Bonjour, je me présente</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          {name}
          {/* Jamal SIDIKOU */}
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">{job}</h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          Je suis un développeur full-stack spécialisé dans l'architecture
          logicielle. Actuellement, je me concentre sur la création
          d'applications web/mobile responsives et de qualité.
        </p>
        <div>
          <Link to="work" smooth={true} duration={500}>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600">
              Voir expériences
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
